let resumeData = {
  name: "TONY VARGHESE",
  role:
    "QA Specialist - Automation Tester - ISTQB Certified - Certified Scrum Master - Full Stack Developer",
  linkedinId: "https://www.linkedin.com/in/tony-varghese-bb9164156/",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/tony-varghese-bb9164156/",
      className: "fa fa-linkedin",
    },
    {
      name: "mail",
      url: "mailto: tony.careermail@gmail.com",
      className: "fa fa-envelope",
    },
  ],
  aboutme:
    "I am QA Specialist with 15+ years of experience in Test. Over the 15 years, i have been part of Functional, Performance and Automation testing. As part of Automation Testing, I have primarily worked on Selenium Webdriver using Java, TestNg, Maven and BDD Framework. I have also dabbled in automating test cases using Python and Pytest Framework. With Innovation taking the center stage and the industry doing a shift left, I was looking forward to upskilling myself. I started my journey with HTML, CSS, Bootstrap, Vanilla JS, Python and Django Framework. I now build full stack apps during my free time.",
  aboutmeagain:
    "Oh !! And did i forget to tell you that i'm ISTQB Certified and a Certified Scrum Master",
  address: "India",
  website: "#",
  certifications: [
    {
      CertificationName: "ISTQB Certified",
      imgurl: "images/ISTQB.png",
      MonthOfPassing: "Feb",
      YearOfPassing: "2009",
      CertificationLink: "https://istqb.in/istqb-foundation/foundationnew/",
    },
    {
      CertificationName: "Certified Scrum Master (PSM1)",
      imgurl: "images/PSM.png",
      MonthOfPassing: "Apr",
      YearOfPassing: "2016",
      CertificationLink: "https://www.scrum.org/certificates/164129",
    },
    {
      CertificationName: "Certified Automation Tester(HP-M102)",
      imgurl: "images/HPUft.png",
      MonthOfPassing: "Jan",
      YearOfPassing: "2016",
      CertificationLink: "#",
    },
  ],
  work: [
    {
      CompanyName: "Some Company",
      specialization: "Some specialization",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2018",
      Achievements: "Some Achievements",
    },
    {
      CompanyName: "Some Company",
      specialization: "Some specialization",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2018",
      Achievements: "Some Achievements",
    },
  ],
  skillsDescription: "Your skills here",
  skills: [
    {
      skillname: "HTML5",
    },
    {
      skillname: "CSS",
    },
    {
      skillname: "Python",
    },
  ],
  portfolio: [
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
    {
      name: "project2",
      description: "mobileapp",
      imgurl: "images/portfolio/project.jpg",
    },
    {
      name: "project3",
      description: "mobileapp",
      imgurl: "images/portfolio/project2.png",
    },
    {
      name: "project4",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
  ],
  testimonials: [
    {
      description:
        "Tony has been a great leader who sets examples for others. Very meticulous & people oriented. He also works relentlessly on innovation agenda and leads from the front",
      name: "Manish Raj, Associate Director, Accenture",
    },
    {
      description:
        "Tony has been the most approachable and amazing lead i ever had. His guidance on test strategies changed the dynamic of the team",
      name: "Sony Salian, Associate Test Manager, Accenture",
    },
  ],
};

export default resumeData;
