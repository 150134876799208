import React, { Component } from "react";
export default class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="certification">
        <div className="row">
          <div className="three columns header-col">
            <h1>
              <span>Certifications</span>
            </h1>
          </div>
          <br />
          <br />

          <div className="rowboot">
            {resumeData.certifications &&
              resumeData.certifications.map(item => {
                return (
                  <div className="col-sm-4-boot">
                    <div className="cardboot certification1Animated">
                      <img
                        src={item.imgurl}
                        alt="istqb"
                        style={{ height: "200px" }}
                      />
                      <div className="cardboot-body">
                        <h4 className="cardboot-title">
                          {item.CertificationName}
                        </h4>
                        <p className="cardboot-text">
                          <span>&bull;</span>{" "}
                          <em className="date">
                            {item.MonthOfPassing} {item.YearOfPassing}
                          </em>
                        </p>
                        <a href={item.CertificationLink} className="button">
                          View Certification
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* <div className="nine columns main-col">
            {resumeData.education &&
              resumeData.education.map(item => {
                return (
                  <div className="row item">
                    <div className="twelve columns">
                      <h3>{item.UniversityName}</h3>
                      <p className="info">
                        {item.specialization}
                        <span>&bull;</span>{" "}
                        <em className="date">
                          {item.MonthOfPassing} {item.YearOfPassing}
                        </em>
                      </p>
                      <p>{item.Achievements}</p>
                    </div>
                  </div>
                );
              })}
          </div> */}
        </div>
        {/* <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            {resumeData.work &&
              resumeData.work.map(item => {
                return (
                  <div className="row item">
                    <div className="twelve columns">
                      <h3>{item.CompanyName}</h3>
                      <p className="info">
                        {item.specialization}
                        <span>&bull;</span>{" "}
                        <em className="date">
                          {item.MonthOfLeaving} {item.YearOfLeaving}
                        </em>
                      </p>
                      <p>{item.Achievements}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <p>{resumeData.skillsDescription}</p>

            <div className="bars">
              <ul className="skills">
                {resumeData.skills &&
                  resumeData.skills.map(item => {
                    return (
                      <li>
                        <span
                          className={`bar-expand ${item.skillname.toLowerCase()}`}
                        ></span>
                        <em>{item.skillname}</em>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div> */}
      </section>
    );
  }
}
