import React, { Component } from "react";
export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="skills">
        {/* QA Skills */}

        <div className="row qaskills">
          <div className="twelve columns header-col">
            <h1>
              <span>QA / Testing Skills</span>
            </h1>
          </div>
          <br />
          <br />
          <br />
          <div className="qaSkillsAnimated">
            <div className="three columns">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Test Planning</h3>
                  <p className="info">
                    <span>&bull;</span>
                    Test Estimation
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Test Approach
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Demand Capacity
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Test Case Design
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Test Design Reviews
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Build Management / Deployments
                  </p>
                </div>
              </div>
            </div>

            <div className="three columns">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Test Execution</h3>
                  <p className="info">
                    <span>&bull;</span>
                    Daily Task Assignment
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Test Execution
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Test Progress Report
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Automation Development
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Automation Execution
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Automation Maintanence
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Raising Incidents/Defects
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Incidents/Defects Housekeeping
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Business Walkthrough and Sign Off
                  </p>
                </div>
              </div>
            </div>

            <div className="three columns">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Test Artefacts</h3>
                  <p className="info">
                    <span>&bull;</span>
                    Test Approach Document
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Test Requirements Document
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Daily Status Report
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Test Closure Report
                  </p>
                </div>
              </div>
            </div>

            <div className="three columns">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Test Strategies</h3>
                  <p className="info">
                    <span>&bull;</span>
                    Capturing Test Metrics
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Process Streamlining and Implementation
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Induction Kit
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Rewards and Recognition
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Proficiency Improvement Framework
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Innovations and Continous Improvements
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Other Skills */}

        <div className="row">
          <div className="twelve columns header-col">
            <h1>
              <span>Other Skills</span>
            </h1>
          </div>
          <br />
          <br />
          <br />
          <div className="otherSkillsAnimated">
            {/* Web Development Skills */}

            <div className="three columns">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Web Development</h3>
                  <p className="info">
                    <span>&bull;</span>
                    Python
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Javascript
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    HTML
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    CSS
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Bootstrap
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Django Framework
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    JSON
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Rest API
                  </p>
                </div>
              </div>
            </div>
            {/* Automation Testing Skills */}

            <div className="three columns">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Automation Testing</h3>
                  <p className="info">
                    <span>&bull;</span>
                    Selenium Webdriver
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Java
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    TestNg
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Maven
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    BDD Framework
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Python
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Pytest Framework
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Pycharm
                  </p>
                </div>
              </div>
            </div>
            {/* Database Skills */}

            <div className="three columns">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Database</h3>
                  <p className="info">
                    <span>&bull;</span>
                    MongoDB
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    MySQL
                  </p>
                </div>
              </div>
            </div>

            {/* Tools */}

            <div className="three columns">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Tools</h3>
                  <p className="info">
                    <span>&bull;</span>
                    Selenium Webdriver
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    UFT
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    SOAPUI
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Postman
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Jira
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Confluence
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    HP ALM
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Visual Studio Code
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Jenkins
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Git
                  </p>
                </div>
              </div>
            </div>
            {/* Experience */}
            <div className="three columns">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Experience</h3>
                  <p className="info">
                    <span>&bull;</span>
                    Agile
                  </p>
                  <p className="info">
                    <span>&bull;</span>
                    Waterfall
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
