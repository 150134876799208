import React, { Component } from "react";

export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;

    return (
      <section id="about">
        <div className="row">
          <div className="three columns aboutProfilePicAnimated">
            <img
              className="profile-pic"
              src="images/profilepic.png"
              alt="profilepic"
            />
          </div>

          <div className="nine columns main-col aboutAnimated">
            <h2>About Me</h2>
            <p>{resumeData.aboutme}</p>

            <p>{resumeData.aboutmeagain}</p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{resumeData.name}</span>
                  <br></br>
                  <span>{resumeData.address}</span>
                  <br></br>
                  <br />
                  <a href="mailto: tony.careermail@gmail.com" class="button">
                    HIRE ME
                  </a>
                  {/* <span>{resumeData.website}</span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
